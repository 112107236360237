<template>
  <div class="search-container ml-md-5">
    <v-card :color="focused ? 'white':'grey lighten-3'" :elevation="focused ? 3 : 0" class="pa-0" >
      <!-- <v-row no-gutters align="center" justify="space-between">
        <v-col> -->
      <v-combobox
        ref="search"
        class="search"
        background-color="transparent"
        :placeholder="searchKind === 'skills' ? 'Baking, Yoga, Presentations, etc..' : 'Name, Skills, Company, etc.'"
        prepend-inner-icon="mdi-magnify"
        solo
        dense flat
        hide-details="true"
        v-model="model"
        cache-items
        :return-object="true"
        :items="items"
        :search-input.sync="search"
        :filter="filterItems"
        item-value="objectID"
        item-color="primary"
        :loading="isLoading"
        auto-select-first
        @input="searchInput"
        @keydown.enter.native="keyDown"
        >


      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <!-- <div class="primary--text mb-2">No results matching "<strong>{{ search }}</strong>".</div> -->
              <div>Press <kbd class="py-1 d-inline">&rdsh; enter</kbd> to search for "{{search}}"</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:item="{item, on , attrs}">
        <template v-if="searchKind === 'people'">
          <template v-if="$route.params.userName === item.userName">
            You're viewing {{item.firstName}}'s profile!
          </template>
          <template v-else>
            <v-list-item-avatar>
              <img :src="item.thumb">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.fullName}}</v-list-item-title>
              <v-list-item-subtitle v-html="item.skills.join(', ')"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:selection="data">
        {{searchKind === 'people' ? data.item.fullName : data.item.name}}

      </template>

    </v-combobox>
    <!-- </v-col> -->
    <!-- <v-col cols="auto">
      <v-select
        class="search-kind"
          v-model="searchKind"
          :items="selects"
          label="Solo field"
          solo flat dense
          hide-details
          :background-color="focused ? 'grey lighten-3' : 'grey lighten-2'"
        ></v-select>
    </v-col> -->
  <!-- </v-row> -->
</v-card>

  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
export default {
  name: 'searchLite',
  // props:['initVal', 'initField','placeholder', 'kind'],
  watch:{
    // model (val) {
    //   process.env.NODE_ENV === "development" ? console.log( 'search val', val ) : null;
    //   // process.env.NODE_ENV === "development" ? console.log( 'this.router.currentRoute.name', this.$router.currentRoute.name ) : null;
    //   if (val != null) {
    //     if (this.searchKind === 'people'){
    //       if(this.$router.currentRoute.name === 'User'){
    //         this.$router.push({ name: 'User', params: { userName: val.userName } }).catch(()=>{});
    //                 this.$refs.search.blur();
    //       }
    //       else{
    //         this.$router.push({ name: 'User', params: { userName: val.userName } }).catch(()=>{});
    //                 this.$refs.search.blur();
    //       }
    //     }
    //     else {this.$router.push({ name: 'Skills', params: { skillId: val.objectID } }).catch(()=>{});
    //             this.$refs.search.blur();
    //   }
    //
    //   }
    // },
    search (query) {
      let vThis = this;
        // Items have already been loaded
        // if (this.items.length > 0) return
        vThis.isLoading = true

        process.env.NODE_ENV === "development" ? console.log( 'search query', query ) : null;
        vThis.index.search(query, vThis.requestOptions)
        .then(({ hits }) => {
          console.log(hits);
          vThis.items = hits;
          vThis.isLoading = false;
        });
      },
      searchKind (val){
        this.setupSearch(val)
      }
  },
  data: () => ({
    model :null,
    search:null,
    items: [],
    isLoading:false,
    index: null,
    client:null,
    requestOptions: null,
    focused:false,
    selects:['skills','people'],
    searchKind: 'people'
  }),
  methods:{
    onFocus(){
      process.env.NODE_ENV === "development" ? console.log( event ) : null;
      this.focused = !this.focused;
    },
    filterItems(item, queryText, itemText) {
      return (item.firstName.toLowerCase().includes(queryText.toLowerCase()) || item.lastName.toLowerCase().includes(queryText.toLowerCase()) || (item.skills.toString()).toLowerCase().includes(queryText.toLowerCase()));
    },
    keyDown(e){
      e.preventDefault();
      process.env.NODE_ENV === "development" ? console.log( 'event', e ) : null;
      if(this.$isEmpty(this.items)){
        this.$router.push({ name: 'Search', query: {q:this.search} }).catch(()=>{});
        this.$refs.search.blur();
      }
    },
    searchInput(data){
      // this.$refs.search.preventDefault();
      process.env.NODE_ENV === "development" ? console.log( 'search data', data, typeof data ) : null;
      if(!this.$isUndefined(data)){
        if(typeof data == 'object'){
          this.routeAfterSearch(data);
        }
      }
    },
    routeAfterSearch(val){
      this.$refs.search.blur();
      if (this.searchKind === 'people'){
        if(this.$router.currentRoute.name === 'User'){
          this.$router.push({ name: 'User', params: { userName: val.userName } }).catch(()=>{});
        }
        else{
          this.$router.push({ name: 'User', params: { userName: val.userName } }).catch(()=>{});
        }
      }
      else {this.$router.push({ name: 'Skills', params: { skillId: val.objectID } }).catch(()=>{});}
      this.model = null;
    },
    setupSearch(val){
      this.client = algoliasearch('P1WZL4Y0AZ', 'dfa6463422ca736108276061d8783e71');
      if(val === 'people'){
        this.index = this.client.initIndex('prod_PEOPLE');
        // this.requestOptions = {
        //   headers: { 'X-Algolia-UserToken': 'user123' }
        // }
      }
      else{
        this.index = this.client.initIndex('prod_SKILLS');
      }
    }
  },
  mounted(){
    this.setupSearch('people');
    let autocompleteInput = this.$refs.search.$refs.input
     autocompleteInput.addEventListener('focus', this.onFocus, true)
    autocompleteInput.addEventListener('blur', this.onFocus, true)
  }
};




</script>


<style lang="scss">
.search-container{
  width:100%;
  .search{
    .v-input__append-inner{
      display: none!important;
    }
  }
  .search-kind{
    width:100px;

    .v-input__slot{
      padding-right: 4px!important;
      border-top-left-radius:0!important;
      border-bottom-left-radius:0!important;
    }
  }
}
</style>
